import { useRouter } from "vue-router";

export const reformatMenu = (data) => {
  const hierarchy = {};
  data.forEach((item) => {
    const code1 = item.code.substring(0, 2);
    const code2 = item.code.substring(0, 4);
    const code3 = item.code.substring(0, 6);

    if (item.code.length === 2) {
      hierarchy[code1] = { ...item, children: {} };
    } else if (item.code.length === 4) {
      hierarchy[code1].children[code2] = { ...item, children: {} };
    } else if (item.code.length === 6) {
      hierarchy[code1].children[code2].children[code3] = { ...item };
    }
  });

  const hierarchyArray = Object.values(hierarchy);

  hierarchyArray.forEach((item) => convertChildrenToArray(item));

  return hierarchy;
};

const convertChildrenToArray = (item) => {
  if (item.children && Object.keys(item.children).length > 0) {
    item.children = Object.values(item.children);
    item.children.forEach((child) => convertChildrenToArray(child));
  } else {
    delete item.children;
  }
};

const splitString = (str: string): string[] => {
  const result: string[] = [];

  for (let i = 2; i <= str.length; i += 2) {
    result.push(str.slice(0, i));
  }

  return result;
};

export const getAllPerm = (list: string[]): string[] => {
  const result: Set<string> = new Set<string>();
  list.forEach((element: string) => {
    const newArr: Set<string> = new Set<string>(splitString(element));
    newArr.forEach((val: string) => {
      result.add(val);
    });
  });

  return Array.from(result);
};

export const checkButtonPermission = (action) => {
  const router = useRouter();
  const { path } = router.currentRoute.value;
  const permissions = JSON.parse(
    localStorage.getItem("acc-permission") as string
  );
  const foundRoute = permissions.find((route) => route.route === path);
  if (foundRoute) {
    return foundRoute.permissions.includes(action);
  } else {
    return false;
  }
};
